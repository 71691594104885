import React from 'react';
import  './About.css';
import AboutChef1 from '../utils/img/about-chef1.jpg' ;  
import AboutChef2 from '../utils/img/about-chef2.jpg' ;
import { ImageGallery } from '../components/ImageGallery';
import { Reviews } from '../components/Reviews';

function About () {
return (
    <div className='about-page'>
        <header className="mt-5">
            <div className="container h-100 d-flex align-items-center justify-content-center">
                <h1 className="text-light"> About </h1>

            </div>
        </header>
        <div className="container my-5">
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis, delectus aperiam quo, iure, optio assumenda dicta tenetur dolorum quibusdam vero dignissimos! Temporibus veritatis facilis cupiditate assumenda architecto ratione perspiciatis inventore dolorem eligendi non, adipisci enim ad eos laudantium quod doloribus aperiam voluptatibus et expedita autem. Provident ab consequatur nesciunt aperiam?</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor provident exercitationem voluptatibus tempore quae vitae adipisci ea, animi quam officia cumque natus voluptate molestiae unde optio vel nemo sunt dignissimos aut! Quisquam, mollitia quaerat. Iusto praesentium sequi mollitia adipisci eaque molestiae harum, consequatur voluptatum laboriosam, similique officiis, dignissimos eius ex temporibus? Veniam error odit iste beatae illum ducimus ullam. Odit, quos consectetur veritatis impedit deserunt quae minus autem sint et voluptas enim ex? Ut voluptate voluptatibus impedit fuga velit beatae?</p>
       
        <div className="row">
            <div className="col-lg-6">
                <img src={AboutChef1} className='img-fluid my-4' alt="" />
            </div>

            <div className="col-lg-6">
                <img src={AboutChef2} className='img-fluid my-4' alt="" />
            </div>
            
            </div> 

            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit dicta hic iusto dignissimos placeat porro eos. Soluta exercitationem quis, natus aperiam debitis ut similique autem dolorum velit aut numquam harum itaque. Totam, enim minima in quasi rem necessitatibus nam alias temporibus accusamus omnis molestias nisi. Dolores dicta, totam quidem nobis voluptatem saepe soluta vitae aut possimus optio tempore, iusto deserunt in quisquam labore maiores magnam eaque. Adipisci cumque commodi quae impedit! Molestiae unde veniam quisquam illo deleniti magnam explicabo eum.</p>      
        </div>

        <div className="bg-dark text-light">
            <ImageGallery/>
        </div>

        <div className="my-5">
            <Reviews/>
        </div>
        
    </div>
)
}

export default About ; 