import React from "react";

export function ContactInfo() {
    return (
        <div className="d-flex flex-column align-items-center">
            <h2 className="fs-1 mb-3 text-uppercase fw-bold"> Where To Find Us </h2>
            <p className="mb-5"> 47 baker Street London LD1 0ND </p>
            <h3>Opening Hours </h3>
            <p className="mb-0"> Mon - Fri: 9:00am - 17:00pm:</p>
            <p> Sat - Sun: 10:00am - 15:00</p>


        </div>
    )
}