import React from 'react';
import { Card, CardBody, CardText, CardTitle, CardFooter } from 'react-bootstrap';
import './Reviews.css';
import person1 from '../utils/img/person1.jpg';
import person2 from '../utils/img/person2.jpg';
import person3 from '../utils/img/person3.jpg';
import person4 from '../utils/img/person4.jpg';

export function Reviews() {
    return (
        <div className="reviews-section container">
            <h2 className="text-center mb-5 text-uppercase fw-bold fs-1"> Reviews </h2>
            <div className="row g-4">
                <div className="col-lg-6"> 
                <Card className="h-100 shadow">
                <CardBody>
                    <div className="p-4">
                        <CardText>
                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Molestias alias corrupti molestiae reprehenderit ullam dolorum totam asperiores quo dicta ipsa, aliquid incidunt nostrum voluptatum doloremque maiores dolorem dignissimos culpa. Sit.
                        </CardText>
                    </div>
                </CardBody>
                <CardFooter className='d-flex align-items-center'>
                    <img src= {person1} className='img-fluid rounded-circle mx-3 shadow' alt="" />
                    <CardTitle className="text-success"> John Mike </CardTitle>
                </CardFooter>
                </Card>
                </div>

                <div className="col-lg-6"> 
                <Card className="h-100 shadow">
                <CardBody>
                    <div className="p-4">
                        <CardText>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, perspiciatis!
                        </CardText>
                    </div>
                </CardBody>
                <CardFooter className='d-flex align-items-center'>
                    <img src= {person2} className='img-fluid rounded-circle mx-3 shadow' alt="" />
                    <CardTitle className="text-success"> Maria Cruz </CardTitle>
                </CardFooter>
                </Card>
                </div>

                <div className="col-lg-6"> 
                <Card className="h-100 shadow">
                <CardBody>
                    <div className="p-4">
                        <CardText>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Optio rem nostrum similique cumque! Quo minus libero, nemo dolores aut quae?
                        </CardText>
                    </div>
                </CardBody>
                <CardFooter className='d-flex align-items-center'>
                    <img src= {person3} className='img-fluid rounded-circle mx-3 shadow' alt="" />
                    <CardTitle className="text-success"> Anna Gold </CardTitle>
                </CardFooter>
                </Card>
                </div>

                <div className="col-lg-6"> 
                <Card className="h-100 shadow">
                <CardBody>
                    <div className="p-4">
                        <CardText>
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quod odio iusto reprehenderit sunt facilis quibusdam sapiente omnis vero explicabo, velit fuga laudantium vitae, amet harum.
                        </CardText>
                    </div>
                </CardBody>
                <CardFooter className='d-flex align-items-center'>
                    <img src= {person4} className='img-fluid rounded-circle mx-3 shadow' alt="" />
                    <CardTitle className="text-success"> Nick Burt </CardTitle>
                </CardFooter>
                </Card>
                </div>

            </div>

        </div>
    )
}
