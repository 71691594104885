import React from 'react';
import './Menu.css';
import { Card, CardBody, CardText, CardTitle } from 'react-bootstrap';
import BreakfastImg from '../utils/img/breakfast.jpg';
import LunchImg from '../utils/img/lunch.jpg';
import DinnerImg from '../utils/img/dinner.jpg';
import DessertImg from '../utils/img/dessert.jpg';

const breakfast = [
    {
        id: 1,
        name: 'English Breakfast',
        description: 'smoked bacon, sausages, tomato, mushrooms, black pudding, baked beans, eggs',
        price: '£12'
    },
    {
        id: 2,
        name: 'Avocado Toast',
        description: 'Avocado, onion, poached eggs, tomato, bread',
        price: '£8'
    },
    {
        id: 3,
        name: 'Burrito',
        description: 'tortilla, egg, cheese, meat',
        price: '£11'
    },
];

const lunch = [
    {
        id: 1,
        name: 'Caesar Salad',
        description: 'chicken breasts, romaine lettuce, croutons, parmesan',
        price: '£15'
    },
    {
        id: 2,
        name: 'Spaghetti Carbonara',
        description: 'spaghetti, eggs, pepper, parmesan, garlic, pancetta',
        price: '£14'
    },
    {
        id: 3,
        name: 'Pizza',
        description: 'chorizo, Italian salami, tomatoes, mushrooms, olives',
        price: '£11'
    },
];

const dinner = [
    {
        id: 1,
        name: 'Spicy Beef',
        description: 'spicy beef, potatoes, carrots, cheese sauce, spices',
        price: '£17'
    },
    {
        id: 2,
        name: 'Spaghetti Bolognese',
        description: 'onion, carrot, celery, minced meat, spaghetti, parmesan',
        price: '£15'
    },
    {
        id: 3,
        name: 'Chickpea Curry',
        description: 'onion, tomatoes, chickpeas, garlic, mushroom, spices',
        price: '£12'
    },
];

const dessert = [
    {
        id: 1,
        name: 'Lemon Cake',
        description: 'flour, baking powder, lemon, sugar',
        price: '£9'
    },
    {
        id: 2,
        name: 'Cinnamon Rolls',
        description: 'flour, sugar, salt, cinnamon, milk, sour cream, yeast',
        price: '£11'
    },
    {
        id: 3,
        name: 'Vegan Pancakes',
        description: 'flour, sugar, baking powder, soy milk, blueberries',
        price: '£8'
    },
];

function Menu() {
    return (
        <div className='menu-page'>
            <header className="mt-5">
                <div className='container h-100 d-flex align-items-center justify-content-center'>
                    <h1 className="text-light">Menu</h1>
                </div>
            </header>

            <div className="breakfast my-5">
                <div className="container">
                    <h2 className="text-center fs-1 mb-4 mb-lg-5 text-uppercase fw-bold text-success">Breakfast</h2>
                    <div className="row flex-column-reverse flex-lg-row">
                        <div className="col-lg-6 d-flex justify-content-center">
                            <img src={BreakfastImg} className='img-fluid w-75 mt-4 mt-lg-0' alt="Breakfast" />
                        </div>
                        <div className="col-lg-6 d-flex flex-column justify-content-around">
                            {breakfast.map((item) => (
                                <div key={item.id}>
                                    <Card className="border-0">
                                        <CardBody className="body">
                                            <CardTitle className="text-center fs-3">
                                                {item.name}
                                            </CardTitle>
                                            <CardText className="text-center fs-5">
                                                {item.description}
                                            </CardText>
                                            <CardText className="text-center fs-3 fw-bold text-success">
                                                {item.price}
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="lunch bg-dark text-light my-5">
                <div className="container">
                    <h2 className="text-center fs-1 mb-4 mb-lg-5 text-uppercase fw-bold text-success">Lunch</h2>
                    <div className="row">
                        <div className="col-lg-6 d-flex flex-column justify-content-around">
                            {lunch.map((item) => (
                                <div key={item.id}>
                                    <Card className="border-0">
                                        <CardBody className="body">
                                            <CardTitle className="text-center fs-3">
                                                {item.name}
                                            </CardTitle>
                                            <CardText className="text-center fs-5">
                                                {item.description}
                                            </CardText>
                                            <CardText className="text-center fs-3 fw-bold text-success">
                                                {item.price}
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </div>
                            ))}
                        </div>
                        <div className="col-lg-6 d-flex justify-content-center">
                            <img src={LunchImg} className='img-fluid w-75 mt-4 mt-lg-0' alt="Lunch" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="dinner my-5">
                <div className="container">
                    <h2 className="text-center fs-1 mb-4 mb-lg-5 text-uppercase fw-bold text-success">Dinner</h2>
                    <div className="row flex-column-reverse flex-lg-row">
                        <div className="col-lg-6 d-flex justify-content-center">
                            <img src={DinnerImg} className='img-fluid w-75 mt-4 mt-lg-0' alt="Dinner" />
                        </div>
                        <div className="col-lg-6 d-flex flex-column justify-content-around">
                            {dinner.map((item) => (
                                <div key={item.id}>
                                    <Card className="border-0">
                                        <CardBody className="body">
                                            <CardTitle className="text-center fs-3">
                                                {item.name}
                                            </CardTitle>
                                            <CardText className="text-center fs-5">
                                                {item.description}
                                            </CardText>
                                            <CardText className="text-center fs-3 fw-bold text-success">
                                                {item.price}
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="dessert bg-dark text-light py-5">
                <div className="container">
                    <h2 className="text-center fs-1 mb-4 mb-lg-5 text-uppercase fw-bold text-success">Dessert</h2>
                    <div className="row">
                        <div className="col-lg-6 d-flex flex-column justify-content-around">
                            {dessert.map((item) => (
                                <div key={item.id}>
                                    <Card className="border-0">
                                        <CardBody className="body">
                                            <CardTitle className="text-center fs-3">
                                                {item.name}
                                            </CardTitle>
                                            <CardText className="text-center fs-5">
                                                {item.description}
                                            </CardText>
                                            <CardText className="text-center fs-3 fw-bold text-success">
                                                {item.price}
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </div>
                            ))}
                        </div>
                        <div className="col-lg-6 d-flex justify-content-center">
                            <img src={DessertImg} className='img-fluid w-75 mt-4 mt-lg-0' alt="Dessert" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Menu;
